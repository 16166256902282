.error-page {
  height: inherit;
  background-color: #e9e9ec;

  .container {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: auto;
    text-align: center;

    .error-form {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      width: 500px;
      height: 360px;
      margin: auto;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
    }

    .error-logo img {
      height: 130px;
    }

    .error-label {
      // text-transform: uppercase;
      letter-spacing: 1px;
      font-size: large;
    }.error-header {
      // text-transform: uppercase;
      letter-spacing: 1px;
      font-size: large;
      font-weight: 800;
    }
    .http-status-header {
      // text-transform: uppercase;
      letter-spacing: 1px;
      font-size: xxx-large;
      font-weight: 800;
    }
    .error-details {
      text-decoration: underline;
      letter-spacing: 1px;
      font-size: small;
    }

    
  }
}

