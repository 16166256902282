.row{
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}
.highlightedCell {
    background-color: #ffe9b3!important;
}

.highlightedAgencent {
    background-color: #fff8e6 ;
}

.mismatchCell {
    color: $red!important;
}

/* Add any additional styles you need */