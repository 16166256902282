
.list-item {
  font-size: 12px !important;
  padding-left: 0 !important;
  &:hover {
      background-color: #f5f5f5;
  
    }
}
.icon {
  width: 20px !important;
  align-self: flex-start;
  margin-right: 5px;
}
.dismiss-icon{
    align-self: flex-start;
  }
.close-icon {
  margin-right: 8px!important;
  padding: 0 !important;
  align-self: flex-start;
}
.time {
  width: 40px;
  align-self: flex-start;
  margin-left: 2px ;
}
.body {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;

  }
}

.list-item .dismiss-icon {
  display: none;
  /* Hide the dismiss icon by default */
}

.list-item:hover .dismiss-icon {
  display: inline;
  cursor: pointer;
  /* Show the dismiss icon on hover */
}

.list-item:hover .time {
  display: none;
}