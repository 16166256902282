.step {
  min-height: 50px;
  // padding-top: 5px;
  border-bottom: 1px solid #C4C4C4;
  
  transition: min-height 0.5s;

  &.open {
    min-height: 100px;
  }

  &.isLast {
    border:0;
  }

  .title {
    font-weight: 600;
  }

  .icon-container {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-weight: 600;

    &.complete {
      background-color: $yellow;
      border: 2px solid $yellow;
    }

    &.todo {
      background-color: $Dark200;
      border: 2px solid $Dark200;
    }

  }

  .step-button {
    height: 30px;
    margin-right: 30px;
  }
}
