.dashboard-chart {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 90px;
  background-color: $white;

  border: 1px solid $Dark300;
  margin-left: 10px;


  align-items: center;
  justify-content: center;

  &.first {
    margin-left: 0;
  }
}