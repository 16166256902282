.navigation-drawer-container {
  background-color: #15161e;
  width: 96px;
  color: $white;

  &.smallMode {
    width: 47px !important;
  }

  .navigation-list__item__image {
    width: 28px;

  }

  .navigation-list__item--title {
    opacity: 0;
  }

  .navigation-list__item__image--small {
    width: 20px;
  }

  .navigation-list__item {
    font-size: 12px;
    position: relative;

    &:last-child {
        padding-bottom: 10px;
      }

    &:first-child {
      background-color: $yellow;
      padding: 8px 0;
      min-height: 73px;


      .navigation-list__item__image {
        width: 52px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.smallMode {
          width: 32px !important;
        }
      }
    }

    .navigation-list__item--title {
      opacity: 1;
    }

    &.navigation-list__item--active {
      background-color: #252734;


      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background-color: $yellow;
      }
    }
  }

  .navigation-list__item__link {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.top {
      height: 71px;
    }

    &.botton {
      height: 55px;
    }

    &:hover {
      background-color: #252734;

      .navigation-list__item--title {
        opacity: 1;
        color: $white;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: inherit
  }
}