.title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.checkbox {
    color: #333;
}



.email-text-field {
    color: #000;
    background-color: #F9F9F9;
    /* Change the background color */
    border-radius: 5px;
    /* Round the corners */
    border: 1px solid #979aa6;
    /* Set the initial border color */
    transition: border-color 0.3s;
    /* Add a transition for hover effect */
    padding: 8px 14px;
    /* Add vertical padding of 8px */
        /* Add left padding of 14px */
    width:100%;

    &.error {
        border: 1px solid red!important;
    }
}

.email-text-field:hover,
.email-text-field:focus,
.email-text-field:active {
    border: 1px solid #FF7227;outline: none;
    /* Change the border color on hover, focus, and click */
}

.setting-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $Dark300;
    height:'80%'
}

.notification-section {
    min-width: 400px;
}

.notification-section-header {
    // font-size: 16px;
    // font-weight: 500;
    text-decoration: underline;
    text-decoration-color: #FF7227;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
}

.notification-section-content-title {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}


