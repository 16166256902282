/* styles.css */

/* Style the container div */
.settings-container {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;

}

/* Style the list */
.settings-list {
    width: 240px;
    padding: 0;
}

/* Style list items */
.settings-list-item {
    text-align: center;
    // transition: background-color 0.3s ease;
    cursor: pointer;

    /* Add more styling as needed */
}

/* Highlight the selected item */
.settings-list-item.selected {
    // background-color: #007bff;
    // color: white;
}