.status {
  padding: 7px 10px;
  display: inline;
  border-radius: 4px;
  text-align: left;
  width: 122px;
  // display: block;

  &.small {
    width: auto;
    padding: 0;
  }

  &.completed {
    background-color: #a0b1c2;
  }

  &.Inconclusive,
  &.Skipped,
  &.pending {
    background-color: $yellow;
  }

  &.Passed,
  &.pass {
    background-color: $green;
  }

  &.Failed,
  &.fail {
    background-color: $red;
  }

  &.table {
    padding: 0;
    display: flex;
    align-items: center;
    // padding-left: 5px;

    &.none {
      display: none;
    }
  }

  &.text {
    color: $black;
    padding: 2px 5px;
  }
}