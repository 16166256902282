.tabs-container {
  .tabs-container__tab {
    min-width: 0;
    padding-left: 14px !important;
    padding-right: 14px !important;
    margin-left: 6px;
    margin-right: 6px;
    color: #15161e !important;

    &.small {
      font-size: 12px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
      margin-left: 2px;
      margin-right: 2px;
  }
  }

  .MuiTabs-indicator {
    height: 4px !important;
    background-color: $yellow;
  }
}
