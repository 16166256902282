/* csslint ignore:start */
/* stylelint-disable */

.cron-parent {
  text-align: center;
}

.cron_builder_bordering {
  border: 1px solid #ddd;
  border-top: none;
  text-align: center;
  padding: 10px;
  background: #fff;
}

.cron_builder_bordering input,
.cron_builder_bordering select {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  padding-left: 5px;
  cursor: pointer;
}

.df {
  display: flex;
}

.cron-builder-bg {
  background-color: $yellow !important;
  color: white;
  text-align: center;
  margin-bottom: 4px;
  padding: 8px 0;
}

.cron_builder_bordering select {
  background-color: white;
  width: 75px;
  cursor: pointer;
  padding: 4px 2px;
  border-radius: 4px;
}

.cron_builder_bordering select option:hover {
  background-color: $yellow;
}

.well-small input {
  width: auto !important;
}

.cron_builder_bordering input[type='radio'] {
  margin-top: 0;
  vertical-align: middle;
}

.cron_builder {
  border: 1px solid #d0cbcb;
  padding: 5px;
  background-color: #dddef13d;
  width: 100%;
  font-size: 14px;
}

.text_align_left {
  text-align: left !important;
}

.cron_builder .nav li {
  cursor: pointer;
  flex: 0 0 85px;
  text-align: center;
}

.cron_builder .nav li a {
  color: $black !important;
}

.cron_builder .nav-tabs .nav-link:focus,
.cron_builder .nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
  background-color: #eee;
}

.cron_builder .nav-tabs .nav-item.show .nav-link,
.cron_builder .nav-tabs .nav-link.active {
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #fff;
}

.cron_builder .well {
  min-height: 20px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}


/* BOOTSTRAP */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
/* csslint ignore:end */
/* stylelint-enable */