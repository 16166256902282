.loading-page {
    height: inherit;
    background-color: #F9F9F9;

    .container {
        position: relative;
        width: 90vw;
        height: 90vh;
        margin: auto;
        text-align: center;

        .loading-form {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;
            width: 400px;
            height: 200px;
            margin: auto;
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            border-radius: 10px;
            box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
        }

        .loading-logo img {
            height: 130px;
        }

        .loading-label {
            // text-transform: uppercase;
            letter-spacing: 1px;
            font-size: large;
        }

    }
}
