.copy-text {
    border-radius: 5px;
    margin: 5px;
    display: inline-block;
    /* Prevents text from moving */
    padding: 5px;
    /* Add some padding for spacing */
    text-decoration: none;
    /* Remove underline from links */
    cursor: pointer;
    transition: background-color 0.2s;
    /* Add a smooth transition effect */
}

.copy-text:hover {
    background-color: rgb(191, 191, 191);
    /* Slightly lighter color on hover */
    border-radius: 5px;
}

.copy-text:active {
    background-color: rgb(212, 212, 212);
    /* Lighter color when pressed */
}

.copy-icon {
    visibility: visible;
    cursor: pointer;
}

.copy-icon-hidden { 
    visibility: hidden
}
