.search-container {
  position: relative;

  .image-container {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  input {
    padding: 14px 50px;
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
  }
}
