.info-container {
  background: #f6f8f9;
  border: 2px solid #e0e6eb;
  box-sizing: border-box;
  margin-top: 11px;

  h2 {
    color: $black;
    display: inline-block;
    position: relative;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 24px;
      height: 2px;
      background-color: $yellow;
      transform: translateX(-50%);
    }
  }

  p {
    color: #979aa6;
    font-size: 12px;
    line-height: 16px;
    overflow-wrap: normal;
  }

  .bar {
    margin-top: 11px;
    max-width: 80px;
  }

  &.textBox {
    border: 2px solid transparent;
    color: #979aa6;
    font-size: 12px;
    line-height: 16px;
    padding: 1px;
    height: 38px;
    margin-top: 1px;
    max-width: 400px;
  }


  &.editable {
    background: #fff;
      border: 1px solid #C4C4C4;
      border-radius: 4px;
      background: #fff;

    &:hover {
      cursor: pointer;
      border: 2px solid #000;
      border-radius: 4px;
      background: #fff;
    }

  }


}