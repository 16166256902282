.side-drawer-container {
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease;
  background-color: $white;
 

  &.show {
    width: 50%;
  }

  .sub-header {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
}

.side-drawer-container-overlay {
  background-color: $white;
  width: 525px;

  .sub-header {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
}
