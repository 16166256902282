html,
body {
  padding: 0;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  background: $grey;
  height: 100%;
}


h1 {
  font-weight: bold;
  font-size: 2em;
}

#root {
  height: 100%;
}

*:not(.material-icons, .editor, .editorLineNumber) {
  font-family: 'Poppins', sans-serif !important;
}

.pointer {
  cursor: pointer;

  &.underline {
    &:hover {
      text-decoration: underline;
    }
  }
}

.full-width {
  width: 100%;
}

.no-width {
  width: 0;
}

.full-height {
  height: 100%;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.success {
  color: $green;
}

.error {
  color: $red;
}

.font-600 {
  font-weight: 600 !important;
}

.break-all {
  word-break: break-all;
}

.pt-web-auth-container {
  height: 100vh;
  overflow: hidden;

  &.with-banner {
    height: calc(100vh - 30px);

  }
}

a.link {
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

.icon-button {
  padding: 8px 8px !important;
  color:rgba(0, 0, 0, 0.7) !important
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #FF7227 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #FF7227;
  border-radius: 10px;
  border: 3px solid #ffffff;
}