* {
  box-sizing: border-box;
}

button {
  text-transform: none !important;
  padding: 8px 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  padding: 0;
  margin: 0;
}

menu {
  padding: 0;
  margin: 0;
  visibility: visible!important;
}

img {
  max-width: 100%;
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}
