$yellow: #FF7227;
$black: #000;
$white: #fff;
$lightblack: #333;


$green: #27ae60;
$red: #eb5757;
$blue: #2d9cdb;
$purple: #bb6bd9;

$amber: #ff9100;

$grey: #F9F9F9;


$Dark200: #E9E9EC;
$Dark300: #CECFD5;
$Dark400: #B2B4BD;
$Dark500: #979AA6;
$Dark600: #7B7F8E;
$Dark700: #636673;
$Dark800: #4D4D56;
$Dark900: #252734;
$Dark1000: #15161E;

$Background400: #EAEEF1;