.form-field-container {
  position: relative;
  // padding-right: 80px;

  .form-field-error-text {
    position: absolute;
    left: 2px;
    bottom: -22px;
    font-size: 13px;
    
    &.dynamic {
      position: static;
      bottom:0;
    }
  }

  .form-field-help-icon {
    position: absolute;
    top: 10px;
    left: 99.5%;

    &.sql-button {
      top: 37px !important;
    }
    &.small {
      top: 0px !important;
    }
  }
}

.error-text {
  font-size: 13px;
  color:#f44336

}

button {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  box-shadow: none !important;
  max-height: 40px;

  &.grey {
    background-color: $Dark600 !important;color: $white !important;
  }&.yellow {
    background-color: $yellow !important;
  }
  &.white {
    background-color: #fff !important;
  }
  &.transparent {
    background-color: transparent;
  }

  &.form-button {
    color: $black;
  }

    &.disabled {
      cursor: not-allowed !important;
      pointer-events: all !important;
    }

  &.square {
    border-radius: 0 !important;
  }
}

.text-field {
  .MuiFilledInput-root {
    background-color: transparent;
    border: 1px solid #979aa6;
    border-radius: 0;
    color: #4d4d56;

    &.Mui-focused {
      background-color: transparent;
    }

    &.Mui-error {
      border: 1px solid #f44336;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .MuiFilledInput-underline {

    &::before,
    &::after {
      border: none;
    }

    &:hover::before {
      border: none;
    }
  }
}

.page-details {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-details__list-container {
    flex: 1;
    overflow-y: hidden;
    transition: width 0.5s ease;
  }

  .page-details-container {
    display: flex;
    flex-direction: column;
    padding-right: 0;

    &.split-screen-open {
      width: 0;
      padding: 0;
      overflow: hidden;
      
    }
  }
}

.select-dropdown {
  background: $white;
  border: 1px solid #d0d9e1;
  width: 135px;
}

.environments {
  .heading {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: $black;
  }

  .manage-environment {
    font-size: 16px;
    border: 2px solid #cecfd5;
    color: #15161e;
    padding: 3px 20px;
  }

  .environment-item {
    border: 1px solid #d0d9e1;
    height: 50px;
    font-size: 16px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}

.iconContainer {
  color: $black;
  padding: 0;

  &.help {
    width: 20px;

    &:hover {
      background-color: transparent;
      color: $blue;
    }
  }

}

.popover-help {
  width: 410px;
  margin: 15px;
  background-color: $white;
}


.close-icon {
  transition: transform .3s ease-in-out;
}

.close-icon:hover {
  transform: rotate(90deg);
}

.italic {
  font-style: italic;
}


.editor {
  counter-reset: line;
  border: 1px solid #ced4da;
  
  max-height: 800px;
  overflow-y: auto!important;

  &.error {
    border: 1px solid $red !important;
  }

  &.large {
    height:200px
  }

}


.editor #codeArea {
  outline: none;
  padding-left: 30px !important;
}


.editor pre {
  padding-left: 30px !important;
  background-color: #fdfdfd !important;
}

.editor .editorLineNumber {
  position: absolute;
  left: 0px;
  color: #000;
  text-align: right;
  width: 20px;
  font-weight: 400;
  background-color: #fdfdfd !important;
  font-family: 'Consolas', monaco, monospace;
}



.pulse-box {
  animation: pulse 2s 2, // Pulse for 2 seconds, 2 times
}

.rotate {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}


.jiggle-on-load {
  animation: jiggle 1.5s;
  /* Adjust the animation duration as desired */
}