.cron-details-container {
  background: #f6f8f9;
  border: 2px solid #e0e6eb;
  color: #666;
  padding: 8px !important;
  padding-left: 16px !important;

  p {
    font-size: 13px;
  }
}
