.visual-card {
  position: relative;
  border-radius: 10px;
  border: 1px;
  min-width: 140px;
  max-width: 140px;
  min-height: 140px;
  max-height: 140px;
  padding: 0px !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    
  &.OOS {
      background-color: rgb(228, 228, 228) !important;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1) !important;
      border: 1px solid rgba(0, 0, 0, 0.12)!important;
  
    }
}

.visual-card-overlay {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    border-radius: 10px;
    /* Match the card's border-radius */
    z-index: 1;
    /* Ensure the overlay is rendered above the card */
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.visual-card-image {
  height: 60px !important;
  display: flex;
  justify-content: center;
}
.visual-card-title {
  padding-top: 5px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
}

.visual-card-button {
  padding: 5px !important;
}

.visual-card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.visual-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 140px;
  max-width: 140px;
  min-height: 140px;
  max-height: 140px;
  background-color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
}
