.title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}
.general-section {
    min-width: 400px;
}

.general-section-content {
    width: 600px;
}



.general-section-header {
    // font-size: 16px;
    // font-weight: 500;
    text-decoration: underline;
    text-decoration-color: #FF7227;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
}

.general-section-content-title {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}


