.list-table {
  width: 100%;

  thead,
  tr,
  th {
    width: inherit;
    font-weight: 600;
    background-color: $white;
  }

  thead,
  th {
    border-bottom: 1px solid #CECFD5;
    background-color: $Background400;
  }


  .column {
    font-size: 13px;
    line-height: 18px;
    padding: 12px;
    border: 0;
    width:fit-content;
    height: 54px;

    &.small {
      padding: 12px;
    }
    
    &.long {
      min-width: 250px !important;
      max-width: 300px !important;
    }        
  }
  
  .menu-column {
    width: 104px;
    // position: relative;
    height: 54px;
    padding:0;

    .menu {
      visibility: hidden;
      }

    .show {
      display: block ;
    }

  }

  

  tr:nth-child(2n) {
    td {
      background-color: #F6F8F9;
    }
  }

  tr:hover {
    td {
      background-color: #E0E6EB;
    }

    .menu {
      visibility: visible;
    }
  }


  tfoot:hover {
    td {
      background-color: $white;
    }
  }

  .skeleton {
    height: 120px;
  }


}

.connection-list-table {
  table-layout: fixed;

  .column:first-child {
    width: 50%;
  }

  .column:nth-child(2) {
    width: 50%;
  }
}

.table-type-2 {
  th {
    background-color: #e0e6eb;
    color: #333;
  }
}

.settings-notifications-list-table {
  table-layout: fixed;
  width: 100%;


  thead,
    tr,
    th {
      font-weight: 600;
      background-color: $white;
    }
  
    thead,
    th {
      border-bottom: 1px solid #CECFD5;
      background-color: $Background400;
    }

  tr:nth-child(2n) {
      td {
        background-color: #F6F8F9;
      }
    }


}

.table-type-2 {
  th {
    background-color: #e0e6eb;
    color: #333;
  }
}