.header {
  background-color: $white;
  height: 73.5px;
  margin-bottom: 15px;
  padding-right: 25px;
  border-bottom: 1px solid $Dark300;

  .page-title {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: $black;
  }

}

.background-pulse {
  position:absolute
}