.snackbar-root {
    @media (min-width: 600px) {
        min-width: 344px !important;
    }

    .card {
        width: 100%;
        background-color: #e5f2fd;

        &.status-passed ,
        &.status-Passed {
            background-color: $green !important;color: #fff
        }
        
        &.status-failed,
        &.status-Failed {
            background-color: #d32f2f !important;
            color:#fff
        }
        
        &.status-inconclusive,
        &.status-Inconclusive,
        &.status-skipped,
        &.status-Skipped {
            background-color: #ff9100 !important;
        }
    }

    .link {
        color:darkblue;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
    }

    .actionRoot {
        padding: 8px 8px 8px 16px;
        justify-content: space-between;
    }

    .icons {
        margin-left: auto;
    }

    .expand {
        padding: 8px 8px;
        transform: rotate(0deg);
        color: #000;
        transition: all 0.2s;
    }

    .expandOpen {
        transform: rotate(180deg);
    }

    .paper {
        background-color: #fff;
        padding: 16px;
    }

}
