.tree-view-style {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Media query for screens smaller than or equal to 768px */
@media (max-width: 768px) {
  .tree-view-style {
    height: 300px; /* Adjust the height for smaller screens */
  }
}

/* Media query for screens larger than 768px and up to 1200px */
@media (min-width: 769px) and (max-width: 1200px) {
  .tree-view-style {
    height: 400px; /* Adjust the height for medium-sized screens */
  }
}

/* Media query for screens larger than 1200px */
@media (min-width: 1201px) {
  .tree-view-style {
    height: 600px; /* Adjust the height for larger screens */
  }
}