.dashboard-page {
  display: flex;
  flex-direction: column;
  height: auto;

  .dashboard-container {
    width: 95%;
    background-color: $white;
    display: flex;
    align-self: center;

    border: 1px solid $Dark300;



    &.no-border {
      border: 0;
    }

    &.no-background {
      background-color: transparent;
    }
  }

  .dashboard-object {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $Dark300;
    flex-grow: 1;

    margin-left: 10px;

    &.first {
      margin-left: 0;
    }

    &.small {
      height: 350px;
    }

    &.no-border {
      border: 0;
    }
  }

  .dashboard-title {
    margin-left: 32px
  }

}