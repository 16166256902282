.login-page {
  height: inherit;
  // background-color: #e9e9ec;
background-image: linear-gradient(45deg,
      hsl(43deg 100% 49%) 0%,
      hsl(40deg 96% 58%) -1%,
      hsl(39deg 99% 63%) 0%,
      hsl(39deg 100% 68%) 1%,
      hsl(38deg 100% 71%) 3%,
      hsl(38deg 100% 75%) 6%,
      hsl(38deg 100% 79%) 9%,
      hsl(38deg 100% 82%) 14%,
      hsl(38deg 100% 86%) 19%,
      hsl(38deg 100% 89%) 27%,
      hsl(38deg 100% 93%) 36%,
      hsl(38deg 100% 96%) 50%,
      hsl(0deg 0% 100%) 86%);
  .container {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: auto;
    text-align: center;

    .login-form {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      width: 500px;
      height: 260px;
      margin: auto;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
    }

    .login-logo img {
      height: 130px;
    }

    .login-label {
      // text-transform: uppercase;
      letter-spacing: 1px;
      font-size: large;
    }

    .contact-card {
      // background-color: #E9E9EC;
      box-shadow: none;
      width: 250px;

      span {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: medium;
      }

      .contact-link {
        background-color: #fec20a;
      }
    }

    .provider-card {
      width: 300px;
      padding: 10px 20px;

      p {
        color: #757575;
        font-weight: 600;
      }

      .provider-image {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.microsoft-login-status-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba($black, 0.4);
  display: none;

  &__container {
    width: 100%;
    height: 100%;
  }

  &__content {
    font-size: 18px;
    padding: 50px 40px;
    width: 40%;
    height: auto;
    background-color: $white;
  }
}
