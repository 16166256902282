.response-container {
  background-color: #f0f3f5;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;

  .header-wrapper {
    background-color: $white;
    border: 1px solid #e0e6eb;
  }

  .icon-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 14px;

    &.success {
      background-color: $yellow;
      border: 2px solid #FF7227;
    }

    &.failed {
      // background-color: $red;
      border: 2px solid $red; //eb5757;
      color: $red;
    }
  }

  .heading {
    font-weight: 500;
    font-size: 16px;
  }

  .description {
    color: $lightblack;
  }

  th,
  td {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
    padding-top: 0;
    font-size: 14px;
    line-height: 24px;

    &:first-child {
      color: #636673;
    }

    &:last-child {
      color: $black;
    }

    &.value-text {
      color: $black;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      width: 70%;
      word-break: break-all;
    }
  }
}
