.test-result-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.test-result-box {
    flex: 1;
    padding: 10px;
    width:40%;

    &.center-div {
        width: 10%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
}

